/**
 * Stop scroll on the body element when a Featherlight container is open.
 * Modifies Featherlight's global defaults to add behaviour when Featherlight is opened and closed.
 * Wrapped in an anonymous function to avoid global var/function creation, and when Jquery.noConflict() is defined.
 */

( function( $, undefined ) {

	if ( typeof $.featherlight !== 'undefined' ) {
		var scroll_top = document.body.scrollTop,
				container_count_open = 0, // To account for nested featherlights, keep track of how many open
				
				freeze_scroll = function() {
					container_count_open++;

					if ( 1 == container_count_open ) {
						scroll_top = document.body.scrollTop;

						$('body').css( {
							'position': 'fixed',
							'top': -(scroll_top) + 'px'
						} );
					}
				},

				restore_scroll = function() {
					container_count_open--;

					if ( 0 == container_count_open ) {
						$('body').css( {
							'position': 'static',
							'top': ''
						} );
						document.body.scrollTop = scroll_top;
					}
				};

		$.featherlight.defaults.afterOpen = freeze_scroll;
		$.featherlight.defaults.beforeClose = restore_scroll;
	}

} )( jQuery );
