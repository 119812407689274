/**
 * Open a cloned version of a form in a Featherlight lightbox, rather than the actual form.
 * This makes sure that IDs aren't repeated across the page, helping with targeting events, clicks on <label>'s etc.
 * By default adds the '-fl' suffix to all 'for' and 'id' attributes.
 */

( function( $, window, document, undefined ) {

	/**
	 * Add a suffix to an element's attribute.
	 * @param {jQuery} $elem    The element to modify.
	 * @param {string} attrName The name of the attribute to modify.
	 * @param {string} suffix   The suffix to add.
	 */
	function addAttrSuffix( $elem, attrName, suffix ) {
		if ( $elem.attr( attrName ) ) {
			$elem.attr( attrName, $elem.attr( attrName ) + suffix );
		}
	}
	
	$('.js-fl-clone-form').click( function(e) {
		var $target = $( $(this).attr('data-fl-target') );

		if ( $target.length ) {
			$target = $target.clone();

			$target.find('label').each( function() {
				addAttrSuffix( $(this), 'for', '-fl' );
			} );

			$target.find('*').each( function() {
				addAttrSuffix( $(this), 'id', '-fl' );
			} );
			
			$.featherlight( $target );
		}

		e.preventDefault();
		return false;
	} );

} )( jQuery, window, document );
