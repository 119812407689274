( function( $, undefined ) {

	$('#filter').submit(function(){
		var filter = $(this);
		$.ajax({
			url:filter.attr( 'action' ),
			data:filter.serialize(),
			dataType: 'html',
			type:filter.attr( 'method' ),
			beforeSend:function(xhr){
				filter.find( '#filter-button' ).addClass( 'rotate-cog' );
				filter.find( '#filter-button' ).addClass( 'button--active' );
				// filter.find( '#filter-button' ).text( 'Filtering...' );
	        	filter.find( '#filter-button' ).removeClass( 'button--tick' );
	        	filter.find( '#filter-button' ).addClass( 'button--filter' );
			},
			success:function(data){
				$( '#response' ).html(data);
				// filter.find( '#filter-button' ).text( 'Filter Projects' );
				filter.find( '#filter-button' ).removeClass( 'button--active' );
				filter.find( '#filter-button' ).removeClass( 'rotate-cog' );
			},
			complete:function( date ){
				// filter.find( '#filter-button' ).text( 'Projects filtered' );
				filter.find( '#filter-button' ).removeClass( 'button--active' );
				filter.find( '#filter-button' ).removeClass( 'rotate-cog' );
				filter.find( '#filter-button' ).removeClass( 'button--filter' );
				filter.find( '#filter-button' ).addClass( 'button--tick' );
			}
		});
		return false;
	});

	$('.filter__checkbox').change(function() {
  	// $( '#filter-button' ).text( 'Filter Projects' );
  	$( '#filter-button' ).removeClass( 'button--tick' );
  	$( '#filter-button' ).addClass( 'button--filter' );
  });

	$("a[href='#main']").click(function(){
   	$('html, body').animate({scrollTop: $('#main').offset().top -0 }, 'slow');

    return false;
	});

	$('#toolbar_filter').submit(function(){
		var filter = $(this);
		$.ajax({
			url:filter.attr( 'action' ),
			data:filter.serialize(),
			dataType: 'html',
			type:filter.attr( 'method' ),
			// beforeSend:function(xhr){
			// 	filter.find( 'button' ).text( 'Processing...' );
			// },
			success:function(data){
				// filter.find( 'button' ).text( 'Sort' );
				$( '#response' ).html(data);
			}
		});
		return false;
	});

	$( '.menu-mobile' ).on( 'touchstart click', function( e ) {
		$( '.menu' ).slideToggle( 400 );
	 	
	 	e.preventDefault();
	 	return false;
	} );

	$( '.toolbar--mobile' ).on( 'touchstart click', function( e ) {
		$( '.toolbar--sectors' ).slideToggle( 400 );
	 	
	 	e.preventDefault();
	 	return false;
	} );

	$( '.filter-toggle' ).on( 'touchstart click', function( e ) {
		$( '.filter' ).slideToggle( 400 );
	 	
	 	e.preventDefault();
	 	return false;
	} );

	$( '.filter .form__checkbox input' ).on( 'change', function( e ) {
  	$( this ).parent().toggleClass( 'button--active', $( this ).prop( 'checked' ) );
	} );
} )( jQuery );
