( function( $, undefined ) {
	$( '.slider--sectors' ).slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
		draggable: false,
		responsive: [
			{
				breakpoint: 1050,
				settings: {
					slidesToShow: 2,
					centerMode: false
				}
			},
			{
				breakpoint: 700,
				settings: {
					slidesToShow: 1,
					centerMode: false
				}
			}
		]
	});

	$( '.callout-slider' ).slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		draggable: false,
		prevArrow: '<button type="button" class="slick-prev"></button>',
		nextArrow: '<button type="button" class="slick-next"></button>'
	});

	$('.js-slick-control').click( function(event) {
		var $slider = $( $(this).data('targetSlider') ),
				$targetSlide = $( $(this).attr('href') ),
				targetIndex = $targetSlide.data('slickIndex');

		if ( $slider.length < 1 ) {
			$slider = $(this).closest('.slick-slider');
			
			if ( $slider.length < 1 ) {
				event.preventDefault();
				return false;
			}
		}

		$slider.slick( 'slickGoTo', targetIndex );

		event.preventDefault();
		return false;
	} );

	$( '.map-slider' ).slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		draggable: false
	});

	$( '.clients-slider' ).slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		speed: 400,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 3,
					centerMode: false
				}
			},
			{
				breakpoint: 750,
				settings: {
					slidesToShow: 2,
					centerMode: false
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1,
					centerMode: false
				}
			}
		]
	});
} )( jQuery );

// Slick Sliders
(function($){
    var blockLoaded = false;
    var blockLoadedInterval = setInterval(function() {
        if (document.getElementById('hero-slider__gallery')) {
            $('#hero-slider__gallery').slick({
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 700,
				dots: false,
				focusOnSelect: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
            });
            blockLoaded = true;
        }
        
        if ( blockLoaded ) {
            clearInterval( blockLoadedInterval );
        }
    }, 500);
})(jQuery);
