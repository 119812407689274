$(document).ready(function() {
    $('.js-switch-map').click( function(event) {
        var target = $(this).attr('href'),
            target_elem = $( target ),
            active = $('.map.is-active');

        if ( target_elem.hasClass('is-active') ) {
            event.preventDefault();
            return false;
        }

        if ( target_elem.length > 0 && active.length > 0 ) {
            target_elem.addClass('is-active');

            active.fadeOut( 125, function() {
                target_elem.fadeIn( 125 );
                active.removeClass('is-active');
            } );

            event.preventDefault();
            return false;
        }

    } );
});
