(function($) {
	var global_zoom = 15,
		zoom_on_scroll = false;

	function render_map( $el ) {
		var $markers = $el.find('.marker'),
			args = {
				zoom		: global_zoom,
				center		: new google.maps.LatLng(0, 0),
				mapTypeId	: google.maps.MapTypeId.ROADMAP,
				scrollwheel : zoom_on_scroll
			},
			map = new google.maps.Map( $el[0], args);

		// add a markers reference
		map.markers = [];

		$markers.each(function(){
	    	add_marker( $(this), map );
		});

		center_map( map );
	}

	function add_marker( $marker, map ) {
		var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') ),
			marker = new google.maps.Marker({
				position	: latlng,
				map			: map
			});

		// add to array
		map.markers.push( marker );

		// if marker contains HTML, add it to an infoWindow
		if( $marker.html() ) {
			var infowindow = new google.maps.InfoWindow({
				content		: $marker.html()
			});

			// show info window when marker is clicked
			google.maps.event.addListener(marker, 'click', function() {
				infowindow.open( map, marker );
			});
		}
	}

	function center_map( map ) {
		var bounds = new google.maps.LatLngBounds();

		$.each( map.markers, function( i, marker ){
			var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
			bounds.extend( latlng );
		});

		if( map.markers.length == 1 ) {
		    map.setCenter( bounds.getCenter() );
		    map.setZoom( global_zoom );
		} else {
			map.fitBounds( bounds );
		}

		$('.map__link').fadeOut( 250 );
	}

	$(document).ready(function(){
		$('.google-map').each(function(){
			render_map( $(this) );
		});
	});

})(jQuery);